import { ButtonCtaComponent } from './button-cta/button-cta.component';
import { LoaderComponent } from './loader/loader.component';
import { InformationBannerComponent } from './information-banner/information-banner.component';
import { PrimaryTitleComponent } from './primary-title/primary-title.component';
import { from } from 'rxjs';

export const components: any[] = [
  ButtonCtaComponent,
  LoaderComponent,
  InformationBannerComponent,
  PrimaryTitleComponent
];

export * from './button-cta/button-cta.component';
export * from './loader/loader.component';
export * from './information-banner/information-banner.component';
export * from './primary-title/primary-title.component';
