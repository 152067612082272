import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { environment } from "./../../environments/environment";

export const appRoutes: Routes = [
  {
    path: '',
    // loadChildren: '../features/booking/booking.module#BookingModule'
    loadChildren: '../features/home/home.module#HomeModule'
  },
  {
    path: 'booking',
    loadChildren: '../features/booking/booking.module#BookingModule'
  },
  {
    path: 'booking-stay',
    loadChildren:
      '../features/booking-stay/booking-stay.module#BookingStayModule'
  },
  {
    path: 'error',
    loadChildren: '../features/error-pages/error-pages.module#ErrorPagesModule'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
