import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

// import { SwiperModule }                   from 'ngx-swiper-wrapper';

import * as fromComponents from './components';
import * as fromServices from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
    /* SwiperModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }) */
  ],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [...fromServices.services]
    };
  }
}
