import { AppCoreComponent } from './app-core/app-core.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

export const containers: any[] = [
  AppCoreComponent,
  HeaderComponent,
  FooterComponent
];

export * from './app-core/app-core.component';
export * from './header/header.component';
export * from './footer/footer.component';
