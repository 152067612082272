import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent implements OnInit {
  @Input() step: any;
  @Input() travel: any;
  @Input() place: any;

  constructor() {}

  ngOnInit() {}
}
